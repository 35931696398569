<template>
  <div>
    <section class="d-flex">
      <header-filters
        is-date-show
        :date-selected="'last_30_days'"
        class-name="dashboard"
        :filter-names="['calendar_type']"
        :product-options="productOptions"
        @onChange="onFiltersChange"
      >
        <v-card>
          <v-select
            v-model="customFilters.location_id"
            filled
            dense
            clearable
            :items="locationsList"
            item-value="id"
            item-text="name"
            :label="$t('location')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.charge_station_id"
            filled
            dense
            clearable
            :items="chargersList"
            item-value="charge_station_id"
            item-text="charge_point_id"
            :label="$t('charge_point_id')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.charger_connector_id"
            filled
            dense
            clearable
            :items="chargeConnectorList"
            item-value="id"
            item-text="charge_point_id_connector_id"
            :label="$t('connector_id')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.status"
            filled
            dense
            clearable
            :items="statusList"
            item-value="id"
            item-text="name"
            :label="$t('status')"
            @change="fetchData"
          />
        </v-card>
      </header-filters>
      <div
        v-if="$admin.can('bookings.create')"
        class="d-flex"
      >
        <v-btn
          color="primary"
          class="ma-0"
          @click="toggleCreateBookingModal"
        >
          Add New
        </v-btn>
      </div>
    </section>
    <section>
      <div class="content-wrapper">
        <v-card
          :loading="isLoading"
          class="data-view"
        >
          <div class="head-btns">
            <div class="data-view-btn">
              <span
                :class="isDataView == 'list_view' && 'active'"
                @click="isDataView = 'list_view'"
              >
                list view
              </span>

              <span
                :class="isDataView == 'calender_view' && 'active'"
                @click="isDataView = 'calender_view'"
              >
                calender view
              </span>
            </div>
          </div>
          <div class="mt-3">
            <div v-if="isDataView == 'list_view'">
              <booking-list-view @clickOnRow="(v) => (eventRow = v)" />
            </div>
            <div v-else-if="isDataView == 'calender_view'">
              <div class="d-flex justify-space-between align-center">
                <div class="d-flex align-center">
                  <div>
                    <v-btn
                      text
                      icon
                      small
                      class="ma-2"
                      @click="$refs.calendar.prev()"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                  </div>
                </div>
                <div>
                  <v-btn
                    text
                    icon
                    small
                    class="ma-2"
                    @click="$refs.calendar.next()"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <section>
                <v-sheet class="bookingCalendar">
                  <v-calendar
                    v-if="options.calendar_type"
                    ref="calendar"
                    v-model="value"
                    :loading="true"
                    :weekdays="weekday"
                    :type="options.calendar_type"
                    :events="events"
                    :event-overlap-mode="options.mode"
                    :event-overlap-threshold="30"
                    :event-color="getEventColor"
                    @click:date="viewDay"
                    @click:event="showEvent"
                    @change="fetchData"
                  />
                  <v-menu
                    :key="selectedEvent.id"
                    v-model="selectedOpen"
                    :close-on-content-click="true"
                    :activator="selectedElement"
                    offset-x
                    class="bookingCardWrap"
                  >
                    <v-card
                      class="mb-0 vcardCustom"
                      max-width="360px"
                      flat
                    >
                      <v-toolbar
                        :color="selectedEvent.color"
                        dark
                      >
                        <!-- For Edit -->
                        <!-- <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn> -->
                        <v-toolbar-title>
                          <div
                            class="d-flex align-center justify-space-between"
                          >
                            <div class="headerLeftWrap">
                              <div class="bookingName">
                                {{ selectedEvent?.name }}
                              </div>
                              <div class="bookingVehicleDetails">
                                <span>{{
                                  selectedEvent?.vehicle_details
                                }}</span>
                              </div>
                            </div>
                            <div class="headerRightWrap">
                              <label>{{ $t("status") }}</label>
                              <div class="statusBadgeWrap">
                                <status-badge :status="selectedEvent?.status" />

                                <span class="statusText capitalize">{{
                                  formatText(selectedEvent?.status)
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </v-toolbar-title>
                        <!-- <v-spacer /> -->
                        <!-- <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn> -->
                      </v-toolbar>
                      <v-card-text class="bookingInsideDetails">
                        <div class="detailsMainWrap">
                          <div class="detailsWrap">
                            <label>Email</label>
                            <span>{{ selectedEvent?.email }}</span>
                          </div>
                          <div class="detailsWrap">
                            <label>Phone No.</label>
                            <span>{{ selectedEvent?.phone_number }}</span>
                          </div>
                        </div>
                        <div class="detailsMainWrap">
                          <div class="detailsWrap fullWidth">
                            <label>{{ $t("location") }}</label>
                            <span>{{ selectedEvent?.location_address }}</span>
                          </div>
                        </div>
                        <div class="detailsMainWrap">
                          <div class="detailsWrap">
                            <label>{{ $t("connector_type") }}</label>
                            <span>{{ selectedEvent?.connector_type }}</span>
                          </div>
                          <div class="detailsWrap">
                            <label>{{ $t("connector_format") }}</label>
                            <span>{{ selectedEvent?.connector_format }}</span>
                          </div>
                        </div>
                      </v-card-text>
                      <v-card-actions
                        v-if="
                          !['rejected', 'cancelled', 'completed'].includes(
                            selectedEvent.status
                          )
                        "
                      >
                        <v-spacer />
                        <!-- <v-btn color="red darken-1" text @click="selectedOpen = false">
                    {{ $t("close") }}
                  </v-btn> -->
                        <v-btn
                          color="primary"
                          text
                          @click="openEditModal(selectedEvent)"
                        >
                          Edit
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </section>
            </div>
          </div>
          <!--  -->
        </v-card>

        <v-card
          :loading="isLoading"
          class="selected-booking"
        >
          <selected-booking
            :event="eventRow"
            @clearData="eventRow = null"
            @editEvent="openEditModal"
          />
        </v-card>
      </div>
    </section>

    <!-- CreateBooking Modal -->
    <create-booking
      v-if="showCreateBookingModal && $admin.can('bookings.create')"
      :is-visible-dialog="showCreateBookingModal"
      :toggle-edit="toggleCreateBookingModal"
      :options="options"
      @change="fetchData"
    />

    <edit-booking
      v-if="showEditBookingModal && $admin.can('bookings.edit')"
      :is-visible-dialog="showEditBookingModal"
      :toggle-edit="toggleEditBookingModal"
      :options="options"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import { nextTick } from "vue";
export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    CreateBooking: () => import("./CreateBooking.vue"),
    EditBooking: () => import("./EditBooking.vue"),
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
    //
    BookingListView: () => import("./util/BookingList.vue"),
    SelectedBooking: () => import("./util/SelectedBooking.vue"),
  },
  data() {
    return {
      eventRow: {},
      isDataView: "list_view",

      showCreateBookingModal: false,
      showEditBookingModal: false,
      showCreateGroupModal: false,
      permissions: {
        create: "bookings.create",
        edit: "bookings.edit",
      },
      options: {
        type: "week",
        mode: "stack",
      },
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      types: ["week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      statusList: [
        {
          id: "pending_approval",
          name: "PENDING APPROVAL",
        },
        {
          id: "approved",
          name: "APPROVED",
        },
        {
          id: "rejected",
          name: "REJECTED",
        },
        {
          id: "rescheduled",
          name: "RESCHEDULED",
        },
        {
          id: "cancelled",
          name: "CANCELLED",
        },
        {
          id: "completed",
          name: "COMPLETED",
        },
        {
          id: "in_progress",
          name: "IN PROGRESS",
        },
      ],
      value: "",
      events: [],
      customFilters: {},
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
      locationsList: "csmsChargeStations/getLocation",
      chargersList: "csmsChargeStations/getChargers",
      chargeConnectorList: "csmsChargeStations/getChargeConnectorList",
    }),
  },
  watch: {
    "customFilters.charge_station_id": {
      handler: function (val) {
        if (val) {
          const params = { charge_station_id: val };
          this.$store.dispatch(
            "csmsChargeStations/chargeConnectorList",
            params
          );
        } else {
          this.$store.commit("csmsChargeStations/SET_CHARGE_CONNECTOR", []);
        }
        this.fetchData();
      },
    },
    selectedOpen(newValue) {
      const calendar = this.$refs.calendar.$el; // Reference to the calendar
      if (newValue) {
        calendar.classList.add("no-scroll");
      } else {
        calendar.classList.remove("no-scroll");
      }
    },
    value() {
      this.selectedOpen = false;
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      // const params = {
      //   tenant_id: this.authUser.independent_fleet_user_nature
      //     ? this.authUser.tenant_id
      //     : this.authUser.parent_tenant_id,
      // };
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("csmsChargeStations/LocationsList", params);
    }
    if (this.$route?.query?.charger_connector_id) {
      this.customFilters.charger_connector_id =
        this.$route.query.charger_connector_id;
    }
    if (this.$route?.query?.location_id) {
      this.customFilters.location_id = parseInt(this.$route.query.location_id);
    }
    if (this.$route?.query?.status) {
      this.customFilters.status = this.$route.query.status;
    }
    if (this.$route?.query?.charge_station_id) {
      this.customFilters.charge_station_id = parseInt(
        this.$route.query.charge_station_id
      );
      if (this.customFilters.charge_station_id) {
        const params = {
          charge_station_id: this.customFilters.charge_station_id,
        };
        this.$store.dispatch("csmsChargeStations/chargeConnectorList", params);
      }
    }
  },
  beforeDestroy() {
    const paramsSet = { ...this.options, ...this.customFilters };
    const params = {
      name: "bookings",
      filters: paramsSet,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    formatText(text) {
      if (!text) return "";
      return text
        .split("_") // Split by underscores
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "); // Join the words with a space
    },
    toggleCreateBookingModal() {
      this.showCreateBookingModal = !this.showCreateBookingModal;
    },
    async openEditModal(item) {
      await this.$store.dispatch("csmsBookings/getBooking", item);
      this.selectedOpen = false;
      this.showEditBookingModal = true;
    },
    toggleEditBookingModal() {
      this.showEditBookingModal = !this.showEditBookingModal;
    },
    toggleCreateGroupModal() {
      this.showCreateGroupModal = !this.showCreateGroupModal;
    },
    onFiltersChange(v) {
      this.options = v;
      if (this.authUser.isAdmin) {
        if (v.tenant_id !== null) {
          const params = { tenant_id: v.tenant_id };
          this.$store.dispatch("csmsChargeStations/LocationsList", params);
          this.$store.dispatch("csmsChargeStations/chargerList", params);
        } else {
          // this.customFilters = {};
          this.$store.commit("csmsChargeStations/SET_LOCATION", []);
          this.$store.commit("csmsChargeStations/SET_CHARGERS", []);
        }
      }
      this.fetchData();
    },
    // fetchData: debounce(async function (val) {
    //   this.options.booking_date = this.options.date;
    //   this.options.booking_status = this.options.status;
    //   this.options.charger_connector_id = this.options.status;
    //   let paramsSet = { ...this.options, ...this.customFilters };
    //   await this.$store.dispatch("csmsBookings/list", paramsSet);
    //   await this.getEvents(val);
    // }, 100),

    fetchData: debounce(async function (val) {
      this.options.booking_date = this.options.date;
      this.options.booking_status = this.options.status;
      this.options.charger_connector_id = this.options.status;

      let paramsSet = { ...this.options, ...this.customFilters };
      await this.$store.dispatch("csmsBookings/list", paramsSet);

      // Store previous event ID (if any)
      const prevSelectedId = this.selectedEvent?.id;

      await this.getEvents(val);

      // Find and reassign the selected event if it still exists
      if (prevSelectedId) {
        this.selectedEvent =
          this.events.find((event) => event.id === prevSelectedId) || {};
      }
    }, 100),

    getEvents() {
      const events = [];
      this.list.forEach((el) => {
        const params = {
          id: el?.booking_id, // Use the new field for customer name
          name: el?.customer_name, // Use the new field for customer name
          phone_number: el?.customer_phone_number, // Updated field
          email: el?.customer_email, // Updated field
          start: new Date(`${el.booking_date} ${el.start_time}`),
          end: new Date(`${el.booking_date} ${el.end_time}`),
          status: el?.booking_status,
          connector_type: el?.connector_type,
          connector_format: el?.connector_format,
          location_address: el?.location_name, // Use `location_name` for address
          vehicle_details: `${el?.vehicle_make_name} ${el?.vehicle_model_name} (${el?.vehicle_year})`, // Combine vehicle details
          //color: el.booking_status === "approved" ? "green" : "grey darken-1",
          color: this.getEventColor({ status: el.booking_status }),
          timed: true,
        };
        events.push(params);
      });

      this.events = events;
      // this.selectedEvent = events;
    },

    //
    viewDay({ date }) {
      this.value = date;
      this.options.calendar_type = "day";
    },

    showEvent({ nativeEvent, event }) {
      this.eventRow = this.list.find((r) => r.booking_id == event.id);
      const updatedEvent = this.events.find((e) => e.id === event.id);
      if (!updatedEvent) return; // Prevent errors if the event was removed

      if (this.selectedEvent?.id === event.id) {
        this.selectedOpen = false;
        nextTick(() => {
          this.selectedEvent = { ...updatedEvent };
          this.selectedElement = nativeEvent.target;
          this.selectedOpen = true;
        });
      } else {
        this.selectedEvent = updatedEvent;
        this.selectedElement = nativeEvent.target;
        this.selectedOpen = true;
      }

      nativeEvent.stopPropagation();
    },

    /*showEvent({ nativeEvent, event }) {
      if (this.selectedOpen) {
        this.selectedOpen = false;
        nextTick(() => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          this.selectedOpen = true;
        });
      } else {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.selectedOpen = true;
      }
      nativeEvent.stopPropagation();
    },*/

    getEventColor(event) {
      //return event.color;
      const statusColors = {
        pending_approval: "#C3B1E1", // Light Purple
        approved: "#87CEEB", // Sky Blue
        rejected: "#fb8787", // Light Red
        rescheduled: "#c1bdbd", // Light Orange
        cancelled: "#fb8787", // Light Red (Same as rejected)
        completed: "#20a390", // Teal Green
        in_progress: "#FFCC80", // Light Orange (Same as rescheduled)
      };

      return statusColors[event.status] || "grey darken-1"; // Default color
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif
$primaryColor: #23BDAA
:deep .v-present
    .v-calendar-daily_head-day-label
        .v-btn
            background-color  : #20a390 !important

:deep .v-past, :deep .v-future
    .v-calendar-daily_head-day-label
        .v-btn
            color : gray !important


:deep .v-toolbar
    width: 100% !important
    .v-toolbar__content, .v-toolbar__title
        width: inherit !important

    .v-toolbar__title
        .number
            font-size: 16px !important
        .email
            font-size: 14px !important
/*
:deep  .v-card__text
    div
        display: flex
        flex-direction: column
        margin-top: 0.5rem
        label
            font-size: 13px !important
            font-weight: 600 !important
        span
            font-size: 16px !important
            */

.bookingCalendar
  height: calc(100vh - 175px)

  :deep .v-calendar .v-event-timed.green
    background-color: #23BDAA !important

  :deep .v-calendar .v-event-timed.grey
    background-color: #9b9b9b !important

.v-menu__content
  background-color: #fff !important
  border-radius: 6px !important
  border: 1px solid #dadada !important
  box-shadow: 0 0 0 !important
  min-width: 360px !important
  max-width: 360px !important
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1))
  :deep .vcardCustom
    background-color: #fff !important
    border-radius: 6px !important
    border: 1px solid #dadada !important
    min-width: 360px
    header
      background-color: #F5F5F5 !important
      border-bottom: 2px solid #DADADA !important
      box-shadow: 0 0 0 !important
      color: #333333
      padding: 0.5rem 0.75rem
      height: auto !important
      .v-toolbar__content
        padding:0
        height: auto !important
      .headerLeftWrap
        display: flex
        flex-wrap: wrap
        flex-direction: column
        .bookingName
          font-size: 0.9375rem
          color: #333
          font-family: $poppinSemi
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
        .bookingVehicleDetails
          font-size: 0.9375rem
          color: #767676
          font-family: $poppinReg
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
      .headerRightWrap
        display: flex
        flex-wrap: wrap
        flex-direction: column
        label
          font-size: 0.9375rem
          color: #333
          font-family: $poppinSemi
        span
          font-size: 0.9375rem

.bookingInsideDetails
  padding: 0

.detailsMainWrap
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  border-bottom: 1px solid #dadada
  padding: 0.5rem .75rem
  .detailsWrap
    display: flex
    flex-wrap: wrap
    flex-direction: column
    width: 50%
    &.fullWidth
      width: 100%
    label
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-size: 0.9375rem
      color: #333
      font-family: $poppinSemi
      width: 90%
    span
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-size: 0.9375rem
      color: #767676
      font-family: $poppinReg
      width: 90%
.statusBadgeWrap
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-left: -1.25rem

  .statusText
    font-size: 0.9375rem
    color: #333
    font-family: $poppinSemi
    text-transform: capitalize


.no-scroll :deep .v-calendar-daily__scroll-area
  overflow: hidden


.content-wrapper
  display: flex
  justify-content: space-between
  gap: 1rem
  margin-top: 2rem
  .v-card
    flex-basis: 100%
  .data-view
    padding-bottom: 1rem
    .head-btns
      padding: 1rem
      display: flex
      justify-content: flex-end
      .data-view-btn
        cursor: pointer
        user-select: none
        display: flex
        gap: 1rem
        background-color: #f4f4f5
        padding: 8px
        font-size: 14px
        font-weight: 600
        color: gray
        border-radius: 5px
        span
          padding: 8px 1rem
          text-transform: capitalize

        .active
          color: #20a390 !important
          background: #ecfdf5 !important
          box-shadow: 1px 1px 8px 0px
          border-radius: 5px



  .selected-booking
    flex-basis: 30%
</style>
